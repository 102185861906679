<template lang="pug">
.advocacy-list
  .d-flex.justify-between.align-center
    h3 Issues
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'advocacies')"
        depressed
        @click="isAdvocacyCreateEditOpen = true"
      ) + New Issue

  .d-flex.justify-between.mt-16.gap-8(
    :class="'w-1/2'"
  )
    v-text-field(
      class="w-full mb-4"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      dense
      clearable
    )

  v-data-table.border.shadow.cursor-pointer(
    must-sort
    :items="advocacies"
    :loading="isPending"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :footer-props="{ itemsPerPageOptions:[25,50,100,-1] }"
    :headers="headers"
    :options.sync="options"
    @click:row="goToAdvocacy"
  )
    template(
      v-slot:item.amount="{ item }"
    )
      v-tooltip( top )
        template( v-slot:activator="{ on, attrs }" )
          h6(
            v-on="on"
            v-bind="attrs"
          ) {{ item.formattedMoney }}
        div Budget: {{ item.formattedBudget }}

  AdvocacyCreateEditDialog(
    :isOpen="isAdvocacyCreateEditOpen"
    @close="closeAndRefresh"
    v-if="isAdvocacyCreateEditOpen"
  )

  ConfirmDialog(
    :title="confirmContext.title"
    :text="confirmContext.text"
    :actionText="confirmContext.actionText"
    :type="confirmContext.type"
    :isOpen="isConfirmDialogOpen"
    @close="closeAndMaybeUpdateUser"
    v-if="isConfirmDialogOpen"
  )

</template>

<script>
import { ref, computed } from '@vue/composition-api'
import AdvocacyCreateEditDialog from './Advocacy.CreateEdit.Dialog.vue'
import ConfirmDialog from '@/components/Dialogs/Confirm.Dialog'
import { upperFirst } from 'lodash'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'

export default {
  name: 'AdvocacyList',

  components: {
    AdvocacyCreateEditDialog,
    ConfirmDialog,
  },

  setup(props, { root: { $router } }) {
    const isAdvocacyCreateEditOpen = ref(false)
    const isConfirmDialogOpen = ref(false)

    const goToAdvocacy = ({ id }) => {
      $router.push({
        name: 'advocacyView', params: { id }
      })
    }
    const closeAndRefresh = () => {
      findadvocacies()
      isAdvocacyCreateEditOpen.value = false;
    };

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'Name',
          value: 'name',
          filterable: true
        },
        {
          text: 'Bill',
          value: 'billNumber',
          filterable: true
        },
        {
          text: 'Budget',
          value: 'formattedBudget',
          filterable: false
        },
        {
          text: 'Dates',
          value: 'dateRange',
          filterable: false
        },
        {
          text: 'Expenses',
          value: 'amount',
          align: 'end'
        }
      ],
      [],
      false
    );
    const stateId = ref([]);
    const filter = computed(() => {
      const filter = {
        $service: 'advocacies',
        $select: ['name', 'billNumber', 'budget']
      }

      return filter
    })
    const {
      items: advocacies,
      isPending,
      pagination,
      findItems: findadvocacies,
      search,
      options
    } = useDataTableFind(
      ['aggregates', 'advocacies'],
      filterableColumns,
      filter,
      { sortBy: ['name'] }
    )

    const date = new Date().toISOString();

    return {
      advocacies,
      isPending,
      headers,
      findadvocacies,
      options,
      pagination,
      search,
      filter,
      stateId,
      date,

      isAdvocacyCreateEditOpen,
      isConfirmDialogOpen,

      goToAdvocacy,
      upperFirst,
      closeAndRefresh
    }
  }
}
</script>

<style>

</style>